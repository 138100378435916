import React, { Component } from "react";
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import SchoolSurveyTableSuggestion from "./SchoolSurveyTableSuggestion";
import { APIServer } from "../util/util";
import { LoadingSpinner } from "../components/LoadingSpinner";

const TableHeadRow = ({ children, className }) => {
	const { width, height, ref } = useResizeDetector();
	return (
		<th ref={ref} className={className ? `${className}` : ""}>
			<span className={className ? `text ${className}` : "text"} style={{ width, height }}>
				{children}
			</span>
		</th>
	);
};

export default class SchoolSurveyTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
			suggestions: [],
		};
	}

	async componentDidMount() {
		this.setState({ isLoaded: false });

		APIServer().get('survey/get_suggestions')
			.then(({ data }) => {
				data.suggestions.sort((a, b) => (b.votes - a.votes));
				this.setState({ suggestions: data.suggestions });
			})
			.catch((error) => {
				// TODO: Display error on page
				this.setState({ error: error });
				console.log(error);
			})
			.then(() => {
				this.setState({ isLoaded: true });
			});
	}

	suggestionOnClick(suggestionID) {
		console.log(suggestionID);
		if(this.props.VotedOnSuggestions.includes(suggestionID)) {
			this.props.onChange(this.props.VotedOnSuggestions.filter(e => e !== suggestionID));
		} else {
			if(this.props.VotedOnSuggestions.length !== 2) {
				var newData = this.props.VotedOnSuggestions;
				newData.push(suggestionID);
				this.props.onChange(newData);
			}
		}
	}

	render() {
		return (
			<div>
				<div className="table-info">
					<h3 htmlFor="othersuggestions">Vote on other suggestions</h3>
					<p id="votesleft">({2 - this.props.VotedOnSuggestions.length} votes left)</p>
				</div>
				{this.state.isLoaded === true ? (
					<div id="table-wrapper">
						<div id="table-scroll">
							<table id="othersuggestionsTable">
								<thead>
									<tr>
										<TableHeadRow>Suggestion</TableHeadRow>
										<TableHeadRow>Votes</TableHeadRow>
										<TableHeadRow className="suggestedBy" >Submitted By</TableHeadRow>
									</tr>
								</thead>
								<tbody>
									{this.state.suggestions.map(suggestion => (
										<SchoolSurveyTableSuggestion 
											key={suggestion._id} 
											suggestion={suggestion} 
											isActive={this.props.VotedOnSuggestions.includes(suggestion._id)}
											onClick={() => this.suggestionOnClick(suggestion._id) }
										/>
									))}
								</tbody>
							</table>
						</div>
					</div>
				) : (
					<div className="Table-Loading">
						<LoadingSpinner />
					</div>
				)}
				<input type="button" value="Submit your own suggestion" onClick={() => this.props.changeState()} />
			</div>
		);
	}
}
