import React, { Component } from 'react'
import { Link, Route } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SurveyModerationPanel from './SurveyModerationPanel/SurveyModerationPanel'
import UserContext from '../context/UserProvider';

export default class AdminPanel extends Component {
    static contextType = UserContext;

    render() {
        return (
            <div id="AdminPanel">
                <div className="SidePanel">
                    <h1> 4Benj.com <br /> Admin Panel </h1>
                    <div className="user">
                        <FontAwesomeIcon icon={faAngleRight}/>
                        <p>Welcome {this.context.user().username} </p>
                    </div>
                    <Link to="/admin"> Home </Link> 
                    <Link to="/admin/users"> Users </Link> 
                    <Link to="/admin/survey"> School Survey Moderation </Link> 
                </div>
                <div className="Content">
                    <Route exact path='/admin/users'>
                        <p>Users</p>
                    </Route>
                    <Route exact path='/admin/survey'>
                        <SurveyModerationPanel />
                    </Route>
                </div>
            </div>
        )
    }
}
