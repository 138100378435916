import React, { Component } from "react";
import { LoadingSpinner } from "../components/LoadingSpinner";
import Modal from "../Modal";
import { APIServer } from "../util/util";
import { Checkmark } from "../components/Checkmark";
import { Cross } from "../components/Cross";
import { generateLoadingText } from "../data/LoadingText";
import './AdminLogin.css'
import UserContext from "../context/UserProvider";

export default class AdminLogin extends Component {

    static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
            isModalOpen: false,
            modalPage: "loading",
            error: "",
            errorcode: ""
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	onLogin(event) {
		event.preventDefault();

		const data = {
			username: this.state.username,
			password: this.state.password,
		};

        this.setState({
            isModalOpen: true,
            modalPage: "loading",
            error: "",
            errorcode: ""
        })

		APIServer().post("user/auth/login", data).then(({data}) => {
            if(data.success === true) {
                setTimeout(() => {
                    this.setState({
                        isModalOpen: true,
                        modalPage: "success",
                        error: "",
                        errorcode: ""
                    })

                    setTimeout(() => {
                        this.context.loginUser(data.token);
                    }, 1500)
                }, 3000);
            } else {
                this.setState({
                    isModalOpen: true,
                    modalPage: "error",
                    error: data.error,
                    errorcode: data.error_code
                })
            }
        }).catch((result) => {
            console.log(result);
            this.setState({
                isModalOpen: true,
                modalPage: "error",
                error: "Please try again later",
                errorcode: "UNKNOWN"
            })
        });
	}

    closeModal() {
        this.setState({
            isModalOpen: false,
            modalPage: "loading"
        })
    }
    
    renderModalPage() {
        if(this.state.modalPage === "loading") {
            return (
                <>
                    <h1>Logging you in...</h1>
                    <p className="subheader"> { generateLoadingText() } </p>
                    <br />
                    <LoadingSpinner />
                </>
            )
        } else if (this.state.modalPage === "success") {
            return (
                <>
                    <h1>Success!</h1>
                    <br />
                    <Checkmark />
                    <br />
                    <p> Please wait while we redirect you </p>
                </>
            )
        } else if (this.state.modalPage === "error") {
            return (
                <>
                    <h1>Fail</h1>
                    <br />
                    <Cross />
                    <br />
                    <p> ERROR_CODE:  {this.state.errorcode} </p>
                    <p> ERROR: {this.state.error}</p>
                    <input type="button" value="Back" onClick={() => this.closeModal() }/>
                </>
            )
        }
    }

	render() {
		return (
			<>
				<form onSubmit={(e) => this.onLogin(e)} className="Login">
					<h1>Login</h1>
					<label htmlFor="username">Username</label>
					<input type="text" id="username" name="username" value={this.state.username} autoComplete="username" onChange={(e) => this.handleInputChange(e)} />
					<label htmlFor="password">Password</label>
					<input type="password" id="password" name="password" value={this.state.password} autoComplete="current-password" onChange={(e) => this.handleInputChange(e)} />
					<input type="submit" value="Login" />
				</form>
				<Modal open={this.state.isModalOpen} onClose={() => this.closeModal()} showClose={false}>
					{ this.renderModalPage() }
				</Modal>
			</>
		);
	}
}
