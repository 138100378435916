import React, { Component } from "react";
import AdminLogin from "./AdminLogin";
import "./Admin.css";
import UserContext from "../context/UserProvider";
import AdminPanel from "./AdminPanel";
import { Redirect } from "react-router-dom";

export default class Admin extends Component {
	static contextType = UserContext;

	render() {
		if (this.context.isLoggedIn() === true) {
			if (this.context.user().permissionLevel !== "user") {
				return <AdminPanel />;
			} else {
				return <Redirect to="/" />;
			}
		} else {
			return <div id="AdminPanel"> <AdminLogin /> </div>;
		}
	}
}
