import React from 'react'
import {ReactComponent as DigitalOcean} from './PoweredByDigitalOcean.svg';

export default function Footer() {
    return (
        <div id="footer">
            <div id="copyright"><p> &copy; 4Benj_ 2021 </p></div>
            <div id="other">
                <a className="digitalOcean" href="https://www.digitalocean.com/?refcode=7a414318e4aa&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"><DigitalOcean /></a>
                {/*<a className="coffee" href="https://www.buymeacoffee.com/4Benj" target="_blank" rel="noreferrer"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Coffee" /></a>*/}
            </div>
        </div>
    )
}
