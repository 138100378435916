import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faInfinity, faSync } from "@fortawesome/free-solid-svg-icons";
import "./SurveyModerationPanel.css";
import UserContext from "../../context/UserProvider";
import Suggestion from "./Suggestion";
import { APIServer } from "../../util/util";

export default class SurveyModerationPanel extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			error: null,
			suggestions: [],
			sortBy: "all"
		};
	}

	componentDidMount() {
		this.FindTheFuckingSuggestions("all");	
	}

	async FindTheFuckingSuggestions(sortBy) {
		this.setState({ isLoaded: false, error: null, sortBy, suggestion: [] });

		const data = {
			userToken: this.context.userToken
		};

		if(sortBy !== "all") {
			data.priorityLevel = sortBy;
		}

		APIServer()
			.post("admin/survey/get_suggestions", data)
			.then(({ data }) => {
				if (data.success === true) {
					data.suggestions.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
					this.setState({ suggestions: data.suggestions, error: null });
				} else {
					// TODO: Display error on page
					console.log(data.error);
				}
			})
			.catch((error) => {
				// TODO: Display error on page
				this.setState({ error: error });
				console.log(error);
			})
			.then(() => {
				this.setState({ isLoaded: true });
			});
	}

	render() {
		return (
			<div className="SMP">
				<h1> Survey Moderation </h1>
				<h2> Pending Suggestions </h2>
				<div className="sortOptions">
					<p>Sort By: </p>
					<button className={this.state.sortBy === "all" ? "selected" : null } onClick={() => this.FindTheFuckingSuggestions("all")}>
						<FontAwesomeIcon icon={faInfinity} />
					</button>
					<button className={this.state.sortBy === "low" ? "selected" : null } onClick={() => this.FindTheFuckingSuggestions("low")}>
						<FontAwesomeIcon icon={faExclamation} />
					</button>
					<button className={this.state.sortBy === "medium" ? "selected" : null } onClick={() => this.FindTheFuckingSuggestions("medium")}>
						<FontAwesomeIcon icon={faExclamation} />
						<FontAwesomeIcon icon={faExclamation} />
					</button>
					<button className={this.state.sortBy === "high" ? "selected" : null } onClick={() => this.FindTheFuckingSuggestions("high")}>
						<FontAwesomeIcon icon={faExclamation} />
						<FontAwesomeIcon icon={faExclamation} />
						<FontAwesomeIcon icon={faExclamation} />
					</button>

					<button style={{float: "right", marginRight: "50px"}} onClick={() => this.FindTheFuckingSuggestions(this.state.sortBy)}>
						<FontAwesomeIcon icon={faSync} />
					</button>
				</div>

				<div className="suggestions-list">
					{this.state.suggestions.map((suggestion) => (
						<Suggestion key={suggestion._id} suggestionData={suggestion} updateList={() => this.FindTheFuckingSuggestions(this.state.sortBy)} />
					))}
				</div>
			</div>
		);
	}
}
