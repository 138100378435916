import React, { Component } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { ReactComponent as AnimatedLogo } from "../animated-logo.svg";
import "./SchoolSurvey.css";
import SchoolSurveyTable from "./SchoolSurveyTable";
import SchoolSurveySuggest from "./SchoolSurveySuggest";
import Modal from "../Modal";
import {generateLoadingText} from '../data/LoadingText';
import { APIServer } from "../util/util";
import { LoadingSpinner } from "../components/LoadingSpinner";

function DetermineErrorMessage(ERROR_CODE) {
	if(ERROR_CODE === 'MISSING_VAR') {
		return (<p>Check that everything is filled in</p>);
	} else if (ERROR_CODE === 'EMAIL_DOMAIN') {
		return (<p>Make sure that you are using a <b>student.education.wa.edu.au</b> email</p>);
	} else if (ERROR_CODE === 'INVALID_EMAIL') {
		return (<p>Make sure your <b>StudentID</b> is correct <br /> (Your StudentID is the thing before the @ in your email).<br />If you believe this is an error, please contact me.</p>);
	} else if (ERROR_CODE === 'STUDENT_EXISTS') {
		return (<p>You have already answered this survey.</p>);
	} else if (ERROR_CODE === 'MISSING_VAR_DEPENDANT') {
		return (<p>Check that everything is filled in (or this is a server side error)</p>);
	} else if (ERROR_CODE === 'UNKNOWN_SUGGESTION') {
		return (<p>One of the suggestions you wanted to vote on couldn't be found... <br /> Refresh the page...</p>);
	} else if (ERROR_CODE === 'SUGGESTION_EXACT_MATCH') {
		return (<p>This suggestion has already been submitted previously</p>);
	} else if (ERROR_CODE === 'ERROR_SAVING') {
		return (<p>Something went wrong while saving...</p>);
	} else if (ERROR_CODE === 'ERROR_SUBMITTING') {
		return (<p>Something went wrong while submitting your vote...</p>);
	}
}

function DetermineNoteMessage(NOTE_CODE) {
	if(NOTE_CODE === 'ERROR_SAVING') {
		return (<p>There was an error saving one of your suggestions...</p>);
	} else if (NOTE_CODE === 'UNKNOWN_SUGGESTION') {
		return (<p>One of the suggestions you wanted to vote on could be found but you managed to vote the other one</p>);
	} else if (NOTE_CODE === 'HIGH_DUPLICATE_CHANCE') {
		return (<p>Your suggestion has a high chance of being a duplicate.</p>);
	} else if (NOTE_CODE === 'MEDIUM_DUPLICATE_CHANCE') {
		return (<p>Your suggestion has a chance of being a duplicate.</p>);
	} else if (NOTE_CODE === 'LOW_DUPLICATE_CHANCE') {
		return (<p>Your suggestion has a low chance of being a duplicate.</p>);
	} 
}

export default class SchoolSurvey extends Component {
	constructor(props) {
		super(props);

		this.state = {
			SuggestIdea: false,
			UserSuggestion: "",
			VotedOnSuggestions: [],
			Anonymous: false,
			PublicAnonymously: false,
			Year: "11",
			Email: "",
			GetUpdatesFromCampaign: false,
			isModalOpen: false,
			ModalHeader: "[ModalHeader]",
			ModalSubHeader: "[ModalSubheader]",
			ModalContent: (<p>[ModalContent]</p>),
			ModalShowLoader: false,
			ModalShowCloseButton: false,
		};
	}

	openModal(header, subheader, content = null, showLoader = false, showCloseButton = false) {
		this.setState({
			isModalOpen: true,
			ModalHeader: header,
			ModalSubHeader: subheader,
			ModalContent: content,
			ModalShowLoader: showLoader,
			ModalShowCloseButton: showCloseButton,
		});
	}

	closeModal() {
		this.setState({
			isModalOpen: false,
			ModalHeader: "[ModalHeader]",
			ModalSubHeader: "[ModalSubheader]",
			ModalContent: (<p>[ModalContent]</p>),
			ModalShowLoader: false,
			ModalShowCloseButton: false,
		});
	}

	async onSurveySubmit() {

		this.openModal("Loading...", generateLoadingText(), null, true, false);

		var interval = setInterval(() => {
			if(this.state.isModalOpen === true && this.state.ModalHeader === "Loading...") {
				this.setState({ModalSubHeader: generateLoadingText() })
			} else {
				clearInterval(interval);
			}
		}, 7500);

		var data = {
			type: this.state.SuggestIdea ? "suggest" : "vote",
			anonymous: this.state.Anonymous,
			anonymousPublicly: this.state.PublicAnonymously,
			receiveCampaignUpdates: this.state.GetUpdatesFromCampaign,
			email: this.state.Email,
		};

		if (!this.state.Anonymous) {
			data.year = parseInt(this.state.Year);
		}

		if (this.state.SuggestIdea) {
			data.newSuggestion = this.state.UserSuggestion;
		} else {
			data.votedSuggestions = this.state.VotedOnSuggestions;
		}

		APIServer().post('survey/submit', data)
			.then(({ data }) => {
				// On Success
				if(data.success === true) {
					setTimeout(() => {
						this.openModal("SUCCESS!", "Thank you for you suggestion!", (
							<>
								<p> It may take a while for your suggestions to get approved and show up on the suggestions list. </p>
								{ typeof data.note !== 'undefined' && data.note !== "LOW_DUPLICATE_CHANCE" ? DetermineNoteMessage(data.note) : null }
							</>
						), false, false);
					}, 5000)
				} else {
					this.openModal("An error occured", "This wasn't meant to happen...", (
						<>
							<p> Sorry... Somethings gone wrong with your submission...  </p>
							<br />
							<p> ERROR_CODE: {data.error_code} </p>
							{DetermineErrorMessage(data.error_code)}
							<input type="button" value="Back" onClick={() => this.closeModal() }/>
						</>
					), false, false);
				}
				
			})
			.catch((error) => {
				// On Fail
				this.openModal("Whoops...", "This should be working, but something's gone wrong...", (
					<>
						<p> Please Try Again Later... </p>
						<input type="button" value="Back" onClick={() => this.closeModal() }/>
					</>
				), false, false);
				console.log(error);
			})
			.then(() => {
				// something here
			});
	}

	render() {
		return (
			<div className="ehshs">
				<div className="content">
					<div className="logos">
						<AnimatedLogo />
					</div>
					<div className="vl"></div>
					<div className="survey" id="survey">
						<OverlayScrollbarsComponent>
							<h1> EHSHS Student Campaign Survey </h1>
							<p>
								Hello! <br />
								I'm Benjamin Elsdon <br />
								I'm running for Head Boy 2022! <br />
								We all recognised there are some areas that need to be improved at this school <br />
								I want to hear from YOU about what YOU want for YOUR school. <br /> 
								That's why I've created this website - to give all students of any year a place to have their voices heard. <br />
								<br />
								Here you can suggest a change that you want to see happen, or vote on some of your peer's suggestions.
								The most popular suggestions that are reasonably achieveble will be integrated into my campaign. <br /> <br />
								I do ask for your school email address but you can choose to remain anonymous. <br />
								If you submit a suggestion and it gets approved, your name will be shown on the suggestion board unless you want to remain annoymous. <br />
								<br />
								Any unrealistic, absurd, vague or duplicate suggestions will be removed from the system, so please remain sensible when submitting a suggestion. <br />
								<br />
								Please be aware that I may publish the results of this survey after the election results are announced regardless of whether I win or not. <br />
								<br />
								If you didn't know you can also check out the other candidates on the <a href="https://www.instagram.com/ehshsheadstudentcampaign/">campaign's Instagram</a> <br />
								If you need help with any parts of the survey, feel free to a email me <a href="mailto:4benj@4benj.com">4benj@4benj.com</a> or message me on <a href="https://www.instagram.com/4Benj_/">Instagram</a> <br />
								<br />
								Remember to vote <b>BENJ</b> to have your voice heard
							</p>
							{this.state.SuggestIdea === true ? (
								<SchoolSurveySuggest
									value={this.state.UserSuggestion}
									onChange={(e) => {
										this.setState({ UserSuggestion: e.currentTarget.value });
									}}
									changeState={() => this.setState({ SuggestIdea: false })}
								/>
							) : (
								<SchoolSurveyTable changeState={() => this.setState({ SuggestIdea: true })} VotedOnSuggestions={this.state.VotedOnSuggestions} onChange={(newData) => this.setState({ VotedOnSuggestions: newData })} />
							)}
							<div className="personal">
								<h3>Personal Information</h3>
								<div className="checkboxes">
									<input
										type="checkbox"
										id="anonymous"
										value={this.state.Anonymous}
										onChange={(e) => {
											this.setState({ Anonymous: e.currentTarget.checked });
										}}
									/>
									<label htmlFor="anonymous"> I would like to remain anonymous </label>
									<br />
									<input
										style={{ marginTop: "15px" }}
										type="checkbox"
										id="anonymouspublic"
										value={this.state.PublicAnonymously}
										onChange={(e) => {
											this.setState({ PublicAnonymously: e.currentTarget.checked });
										}}
									/>
									<label htmlFor="anonymouspublic"> I would like to remain anonymous publicly </label>
								</div>
								<div className="info">
									<div className={this.state.Anonymous ? "email full" : "email"}>
										<label htmlFor="email">Email address</label>
										<input
											id="email"
											type="email"
											placeholder="john.smith@student.education.wa.edu.au"
											value={this.state.Email}
											onChange={(e) => {
												this.setState({ Email: e.currentTarget.value });
											}}
										/>
									</div>
									{this.state.Anonymous === false ? (
										<div className="year">
											<label htmlFor="grade">Year</label>
											<select
												id="grade"
												value={this.state.Year}
												onChange={(e) => {
													this.setState({ Year: e.currentTarget.value });
												}}
											>
												<option value="7">7</option>
												<option value="8">8</option>
												<option value="9">9</option>
												<option value="10">10</option>
												<option value="11">11</option>
												<option value="12">12</option>
											</select>
										</div>
									) : null}
								</div>
								<p className="disclaimer">
									Becuase of security concerns, your email address is required in order to vote regardless of whether you choose to remain anonymous or not.
									<br />I promise I will not spam or send junkmail and you will only get emails about your suggestion (If you did submit your own suggestion) or updates about the campaign (if you click the checkbox below).{" "}
								</p>
								<input
									style={{ marginBottom: "10px" }}
									type="checkbox"
									id="GetUpdatesFromCampaign"
									value={this.state.GetUpdatesFromCampaign}
									onChange={(e) => {
										this.setState({ GetUpdatesFromCampaign: e.currentTarget.checked });
									}}
								/>
								<label htmlFor="GetUpdatesFromCampaign"> I would like to receive updates about the campaign </label>
							</div>
							<input type="button" value="Submit" onClick={() => this.onSurveySubmit()} />
							<br />
						</OverlayScrollbarsComponent>
					</div>
				</div>
				<Modal open={this.state.isModalOpen} onClose={() => this.closeModal()} showClose={this.state.ModalShowCloseButton}>
					<h1> {this.state.ModalHeader} </h1>
					{this.state.ModalSubHeader !== "" ? <p className="subheader"> {this.state.ModalSubHeader} </p> : null}
					{this.state.ModalShowLoader ? (
						<LoadingSpinner />
					) : null}
					{this.state.ModalContent !== null ? this.state.ModalContent : null}
				</Modal>
			</div>
		);
	}
}
