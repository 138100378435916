import React from 'react'

export default function SchoolSurveySuggest({onChange, value, changeState}) {
    return (
        <div>
            <div>
				<label htmlFor="change">Suggestion</label>
				<input
					id="change"
					type="text"
					placeholder="Blah blah blah blah blah"
					value={value}
					onChange={(e) => {
						onChange(e);
					}}
					maxLength="255"
				/>
				<input type="button" value="Vote on other's suggestions" onClick={() => changeState()} />
			</div>
        </div>
    )
}
