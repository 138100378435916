import React from 'react'
import "./Cross.css"

export const Cross = () => {
    return (
        <svg className="cross" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="cross__circle" cx="26" cy="26" r="25" fill="none"/>
            <path className="cross__check" fill="none" d="M16 16 l 20 20" strokeWidth="4"/>
            <path className="cross__check" fill="none" d="M36 16 l -20 20" strokeWidth="4"/>
        </svg>
    )
}
