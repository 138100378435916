import React, { Component } from 'react'

export default class SchoolSurveyTableSuggestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestedBy: "please wait..."
        };
    }

    componentDidMount() {
        if(this.props.suggestion.suggestedBy === "Anonymous" || this.props.suggestion.suggestedBy === "LOADING" || this.props.suggestion.suggestedBy === "ERROR") {
            this.setState({suggestedBy: this.props.suggestion.suggestedBy});
        } else {
            var str = this.props.suggestion.suggestedBy;
            var n=str.lastIndexOf(" (");
            this.setState({suggestedBy: str.substring(0,n)+"<br />"+str.substring(n)});
        }
    }

    render() {
        return (
            <tr className={this.props.isActive ? 'voted': null} onClick={() => this.props.onClick()} >
                <td>{this.props.suggestion.suggestion}</td>
                <td>{this.props.suggestion.votes}</td>
                <td className="suggestedBy" dangerouslySetInnerHTML={{__html: this.state.suggestedBy}}></td>
            </tr>
        )
    }
}
