import React, { useContext, useCallback } from 'react'
import { useCookies } from 'react-cookie';
import { APIServer, ReactIsInDevelopmentMode } from '../util/util';
import jwt from 'jsonwebtoken'

const UserContext = React.createContext();

export function useUser() {
    return useContext(UserContext);
}

export function UserProvider({ children }) {
    const [cookies, setCookie, removeCookie] = useCookies(['userToken']);

    const validateUser = useCallback((token) => {
        if(typeof cookies.userToken !== 'undefined' && cookies.userToken !== null) {
            return false;
        }

        APIServer.post('user/auth/verifyToken', { token }).then(({data}) => {
            if(data.success === false) {
                removeCookie('userToken');
                return false
            }
            return data.success;
        }).catch(() => {
            removeCookie('userToken');
            return false;
        });
    }, [removeCookie, cookies]);

    const isLoggedIn = useCallback(() => {
        if(typeof cookies.userToken === 'undefined' || cookies.userToken === null) {
            return false;
        }

        return true;
    }, [cookies]);

    const loginUser = useCallback(async (token) => {
        setCookie('userToken', token, {
            path: '/',
            maxAge: 3600, // one hour
            httpOnly: false,
            secure: ReactIsInDevelopmentMode() ? false : true
        });

        return true;
    }, [setCookie]);

    const logoutUser = useCallback(() => {
        removeCookie('userToken');
    }, [removeCookie]);

    const getUserData = useCallback(() => {
        return jwt.decode(cookies.userToken);
    }, [cookies]);

    return (
        <UserContext.Provider value={{loginUser, logoutUser, validateUser, isLoggedIn, user: getUserData, userToken: cookies.userToken}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext