import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars, faCheck, faExclamation, faPercentage, faQuestion, faTimes } from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";
import { APIServer } from "../../util/util";
import UserContext from "../../context/UserProvider";

export default function Suggestion({ suggestionData, updateList }) {
	const user = useContext(UserContext);

	const displayKeywords = () => {
		const keywords = JSON.parse(suggestionData.keywords);

		if (keywords.length === 0) {
			return "NONE";
		} else {
			var keywordsToDisplay = keywords.splice(0, Math.min(keywords.length, 5));

			var display = keywordsToDisplay.join(", ");
			if (keywords.length > 0) {
				display += ` +${keywords.length} more`;
			}

			return display;
		}
	};

	const displayReviewStatus = () => {
		if (suggestionData.reviewStatus === "high") {
			return (
				<>
					<FontAwesomeIcon icon={faExclamation} />
					<FontAwesomeIcon icon={faExclamation} />
					<FontAwesomeIcon icon={faExclamation} />
				</>
			);
		} else if (suggestionData.reviewStatus === "medium") {
			return (
				<>
					<FontAwesomeIcon icon={faExclamation} />
					<FontAwesomeIcon icon={faExclamation} />
				</>
			);
		} else if (suggestionData.reviewStatus === "low") {
			return (
				<>
					<FontAwesomeIcon icon={faExclamation} />
				</>
			);
		} else {
			return (
				<>
					<FontAwesomeIcon icon={faQuestion} />
				</>
			);
		}
	};

	const getHighestMatch = () => {
		var hm = { keyword_match_percentage: 0 };
		suggestionData.possibleDuplicates.forEach((potentialDuplicate) => {
			if (potentialDuplicate.keyword_match_percentage > hm.keyword_match_percentage) {
				hm = potentialDuplicate;
			}
		});

		return hm;
	};

	const updateSuggestion = (command, notes = null) => {
		const data = {
			userToken: user.userToken,
			suggestionID: suggestionData._id,
			command: command
		};

		if(notes != null) {
			data.notes = notes;
		}

		APIServer().post("admin/survey/update_suggestion", data)
			.then(({ data }) => {
				if (data.success === true) {
					updateList();
				} else {
					// TODO: Display error on page
					console.log(data.error);
				}
			})
			.catch((error) => {
				// TODO: Display error on page
				console.log(error);
			})
	};

	return (
		<div className="suggestion">
			<div className="l-panel">
				<h3> {suggestionData.suggestion} </h3>
				<div className="var">
					<p className="head">Submitted by</p>
					<p>{suggestionData.suggestedBy}</p>
				</div>
				<div className="var">
					<p className="head">KEYWORDS</p>
					<p>{displayKeywords()}</p>
				</div>
			</div>
			<div className="r-panel">
				<div className="PrioityLevel">
					<p>{displayReviewStatus()}</p>
				</div>
				<div className="var">
					<p className="head">POTENTIAL MATCHES</p>
					<p>
						{suggestionData.possibleDuplicates.length} potential match{suggestionData.possibleDuplicates.length !== 1 ? "es" : null}{" "}
					</p>
				</div>
				<div className="var">
					<p className="head">HIGHEST MATCH</p>
					<p>
						{suggestionData.possibleDuplicates.length > 0 ? getHighestMatch().keyword_match_percentage : "0"}
						<FontAwesomeIcon icon={faPercentage} />
					</p>
				</div>
				<div className="var">
					<p className="head">SUGGESTED ON</p>
					<p>{Moment(suggestionData.dateCreated).format("ddd, Do of MMMM YYYY, h:mm:ss a")}</p>
				</div>
			</div>
			<div className="buttons">
				<button className="approve" onClick={() => updateSuggestion("accept")}>
					<FontAwesomeIcon icon={faCheck} /> Approve
				</button>
				<button className="reject" onClick={() => updateSuggestion("reject")} style={{ marginLeft: "20px" }}>
					<FontAwesomeIcon icon={faTimes} /> Reject
				</button>
				<button className="more" style={{ marginLeft: "20px" }}>
					<FontAwesomeIcon icon={faBinoculars} /> View more
				</button>
			</div>
		</div>
	);
}
