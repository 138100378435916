import "./Home.css";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as AnimatedLogo } from "./animated-logo.svg";

function Home() {
	return (
		<div className="Home">
			<div className="landing">
				<div className="content">
					<AnimatedLogo />
					<div className="text">
						<h1>Hello.</h1>
						<p>
							I'm Benj.
							<br />
							I'm a full stack web developer and game developer.
						</p>
						<div className="links">
							{/*
							<p> Blog </p>
							<p> Projects </p>
							<p> Social Media </p>
							*/}
						</div>
					</div>
				</div>
				<div className="scroll" id="scroll-down">
                	{/*
					<div className="arrow">
						<FontAwesomeIcon icon={faAngleDown} />
                	</div>
                	<p> SCROLL FOR MORE </p>
                	<div className="arrow">
						<FontAwesomeIcon icon={faAngleDown} />
					</div>
					*/}
					<p> MORE COMING SOON </p>
            	</div>
			</div>
			{/*
			<div className="about-me">
				<h1>About Me</h1>
			</div>
			<div className="latest-blog">
				<h1>Blog</h1>
			</div>
			*/}
		</div>
	);
}

export default Home;
