import React from "react";
import {RotateLoader} from "react-spinners";

export const LoadingSpinner = () => {
	return (
		<div className="loading-spinner">
			<div className="spinner">
				<RotateLoader loading={true} size={10} color={"#f9cb26"} />
			</div>
		</div>
	);
};
