import React, { Component } from "react";
import SchoolSurvey from "./SchoolSurvey"
import "./School.css";

function getTimeRemaining(endtime) {
	const total = Date.parse(endtime) - Date.parse(new Date());
	const seconds = Math.floor((total / 1000) % 60);
	const minutes = Math.floor((total / 1000 / 60) % 60);
	const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
	const days = Math.floor(total / (1000 * 60 * 60 * 24));

	return {
		total,
		days,
		hours,
		minutes,
		seconds,
	};
}

export default class School extends Component {
	constructor(props) {
		super(props);
        this.campaignStartsIn = "August 6 2021 1:00:00 GMT+0800"
		this.state = {
			timeRemaining: getTimeRemaining(this.campaignStartsIn),
            unlockStep: 0
		};
	}

	componentDidMount() {
		if (this.state.timeRemaining.total > 0) {
			this.interval = setInterval(() => this.setState({ timeRemaining: getTimeRemaining(this.campaignStartsIn) }));
            document.body.addEventListener('keydown', (e) => this.onKeyPress(e));
		}
	}

    onKeyPress(event) {
        var pattern = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a', "Enter"];
        if (pattern.indexOf(event.key) < 0 || event.key !== pattern[this.state.unlockStep]) {
            this.setState({ unlockStep: 0 });
            return;
        }
    
        // Update how much of the pattern is complete
        this.setState({ unlockStep: this.state.unlockStep + 1 });
    
        // If complete, alert and reset
        if (pattern.length === this.state.unlockStep) {
            this.setState({ unlockStep: 0, timeRemaining: 0 });
            clearInterval(this.interval);
        }
    }

	render() {
		if (this.state.timeRemaining.total > 0) {
			return (
				<div className="countdown">
					<div className="content">
						<h1> I <b>should</b> finish the survey site in... </h1>
						<div className="counter">
							<div className="container">
								<div className="center">
									<p>{this.state.timeRemaining.days}</p>
									<p>D</p>
								</div>
							</div>
							<div className="container">
								<div className="center">
									<p>{this.state.timeRemaining.hours}</p>
									<p>H</p>
								</div>
							</div>
							<div className="container">
								<div className="center">
									<p>{this.state.timeRemaining.minutes}</p>
									<p>M</p>
								</div>
							</div>
							<div className="container">
								<div className="center">
									<p>{this.state.timeRemaining.seconds}</p>
									<p>S</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
            return (
                <SchoolSurvey />
            );
		}
	}
}
