import React from 'react';
import axios from "axios";

function ReactIsInDevelopmentMode(){ 
    return '_self' in React.createElement('div');
}

function APIServer() {
    if (!ReactIsInDevelopmentMode()) {
		return axios.create({
			baseURL: `/api`,
		});
	} else {
        return axios.create({
			baseURL: `http://localhost:4000/api/`,
		});
	}
}

export { ReactIsInDevelopmentMode, APIServer }