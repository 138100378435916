import React from "react";
import "./App.css";
import Home from "./Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import School from "./school/School";
import Error404 from "./Error404";
import Admin from "./admin/Admin";
import { CookiesProvider } from "react-cookie";
import { UserProvider } from "./context/UserProvider";
import Copyright from "./Copyright";

export default function App() {
	return (
		<CookiesProvider>
			<UserProvider>
				<Router>
					<div className="App">
						<Switch>
							<Route exact path="/">
								<Home />
							</Route>
							<Route exact path="/school">
								<School />
							</Route>
							<Route path="/admin">
								<Admin />
							</Route>
							<Route component={Error404} />
						</Switch>
						<Copyright />
					</div>
				</Router>
			</UserProvider>
		</CookiesProvider>
	);
}
