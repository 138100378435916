import React from 'react'
import ReactDom from 'react-dom';

export default function Modal({ open, children, onClose, showClose }) {
    if(!open) return null;
    return ReactDom.createPortal(
        <>
            <div className="modalOverlay" />
            <div className="modal">
                { typeof showClose !== 'undefined' && showClose !== false ? <button onClick={onClose}> Close Modal </button> : null }
                {children}        
            </div>
       </>,
       document.getElementById('portal')
    )
}