import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Error404 extends Component {
    render() {
        return (
            <div className="E404">
                <h1>Error 404</h1>
                <p>The page your looking for couldn't be found</p>
                <p>Yeah.. I can't be bothered to style this...</p>
                <br />
                <h3>Go find something else to do.</h3>
                <p>Here are some of my suggestions (if you want to stay on my site)</p>
                <p><Link to="/">My Home Page</Link></p>
                <p><Link to="/school">School Campaign Survey</Link></p>
            </div>
        )
    }
}
